














































import PunishMessage from "../components/PunishMessage.vue";
import { InputSetups } from "@/mixins/input-setups";
import PunishTimeWrapper from "../components/PunishTimeWrapper.vue";
import { BanAction } from "../..";
import { ClassProperties } from "@/includes/types/util.types";

import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';

@Component({
  components: {
    PunishTimeWrapper,
    PunishMessage,
    TimeUnitInput,
    SwitchInput,
    NestedContent
  },
  data() {
    return {
      StepUnit
    }
  }
})
export default class BanActionView extends Mixins(InputSetups) {
  @VModel() model!: BanAction

  @Prop() defaultModel!: Partial<ClassProperties<BanAction>>

  @Prop({ type: Boolean, default: true, required: false }) hasMaxTimeMessage!: boolean
}
